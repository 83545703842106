import * as queryString from "query-string-object";
import baseRequest from "./baseReuqest";

export function getGreenApiData(page, per_page, order, order_by) {
    let url = '/api/whatsapp/green_api';
    const q = queryString.stringify({page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export async function getAllInstances(page, per_page, order, order_by) {
    let url = '/api/instance/all';
    const q = queryString.stringify({page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}

export function  getWebhookHistory(page, per_page, order,order_by) {
    let url = '/api/instance/webhook-history';
    const q = queryString.stringify({page, per_page, order, order_by});
    if (q) {
        url += `?${q}`;
    }
    return baseRequest(url);
}