import React from 'react';
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import DataTable from "../../components/data-table/DataTable";
import {useTranslation} from "react-i18next";
import {getWebhookHistory} from "../../services/greenApi";
import {navLinks} from "./GreenApiPage";
import moment from "moment";

const columnData = [
    {id: 'instanceId', numeric: false, disablePadding: false, label: 'Instance Id'},
    {id: 'webhookMessage', numeric: false, disablePadding: false, label: 'Message text'},
    {id: 'webhookType', numeric: false, disablePadding: false, label: 'Webhook Type'},
    {id: 'stateInstance', numeric: false, disablePadding: false, label: 'Instance State'},
    {
        id: 'createdAt',
        numeric: false,
        label: 'Date created',
        processValue: (val) => {
            return moment(val).format('DD.MM.YYYY HH:mm:ss')
        }
    },
];

const getTableData = (...params) => {
    return getWebhookHistory(...params);
}

export default function WebhookHistoryPage() {
    const {t} = useTranslation();

    return (
        <>
            <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}/>
            <Content title={t("Webhook History")}>
                <DataTable
                    columnData={columnData}
                    dataFunc={getTableData}
                    selector={false}
                />
            </Content>
        </>
    )
}