import React from "react";
import {Route, Switch} from "react-router";
import MessagesPage from "./MessagesPage";
import InstancesPage from "./InstancesPage";
import SendMessagePage from "./SendMessagePage";
import WebhookHistoryPage from "./WebhookHistoryPage";

export const navLinks = [
    {path: "/green-api", text: "Messages"},
    {path: "/green-api/instances", text: "Instances"},
    {path: "/green-api/send-message", text: "Send messages"},
    {path: "/green-api/webhook-history", text: "Webhook History"},
]

export default function GreenApiPage() {
    return (
        <>
            <Switch>
                <Route exact path="/green-api" component={MessagesPage}/>
                <Route path="/green-api/instances" component={InstancesPage}/>
                <Route path="/green-api/send-message" component={SendMessagePage}/>
                <Route path="/green-api/webhook-history" component={WebhookHistoryPage}/>
            </Switch>
        </>
    )
}